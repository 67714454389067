export default (apiURL, methods) => {
    return {
        createAssignmentByClassId(params = {}) {
            // NOTE: action=assignments_create_by_story_by_class_id
            const url = `${apiURL}/teacher/classes/${params.classId}/assignments/create`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
                quiz_id: params.quizId,
                question_id: params.questionId,
                story_id: params.storyId,
                students_ids: params.studentsIds,
            });
        },
    };
};
