import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    assignmentsByPage: {},
    completedAssignmentsByPage: {},
    incompleteAssignmentsByPage: {},
    incompleteAssignmentsByClassId: {},
    completedAssignmentsByClassId: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE_LOADING: {
            const prevIncompleteAssignmentsByPage = state.incompleteAssignmentsByPage || {};

            return {
                ...state,
                incompleteAssignmentsByPage: {
                    ...prevIncompleteAssignmentsByPage,
                    isLoading: true,
                },
            };
        }

        case types.SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE: {
            const {
                data,
                error,
                hasMore,
            } = action.payload;

            const prevData = state.incompleteAssignmentsByPage?.data || [];

            let page = state.incompleteAssignmentsByPage?.page || 0;

            if (!error) {
                page += 1;
            }

            return {
                ...state,
                incompleteAssignmentsByPage: {
                    data: [
                        ...prevData,
                        ...data,
                    ],
                    page,
                    error,
                    hasMore,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_INCOMPLETE_ASSIGNMENTS: {
            return {
                ...state,
                incompleteAssignmentsByPage: {},
            };
        }

        /* --- */

        case types.SET_ASSIGNMENTS_BY_PAGE_LOADING: {
            const prevAssignmentsByPage = state.assignmentsByPage || {};

            return {
                ...state,
                assignmentsByPage: {
                    ...prevAssignmentsByPage,
                    isLoading: true,
                },
            };
        }

        case types.SET_ASSIGNMENTS_BY_PAGE: {
            const {
                data,
                error,
                hasMore,
            } = action.payload;

            const prevData = state.assignmentsByPage?.data || [];

            let page = state.assignmentsByPage?.page || 0;

            if (!error) {
                page += 1;
            }

            return {
                ...state,
                assignmentsByPage: {
                    data: [
                        ...prevData,
                        ...data,
                    ],
                    page,
                    error,
                    hasMore,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_ASSIGNMENTS_BY_PAGE: {
            return {
                ...state,
                assignmentsByPage: {},
            };
        }

        /* --- */

        case types.SET_COMPLETED_ASSIGNMENTS_BY_PAGE_LOADING: {
            const prevAssignmentsByPage = state.completedAssignmentsByPage || {};

            return {
                ...state,
                completedAssignmentsByPage: {
                    ...prevAssignmentsByPage,
                    isLoading: true,
                },
            };
        }

        case types.SET_COMPLETED_ASSIGNMENTS_BY_PAGE: {
            const {
                data,
                error,
                hasMore,
            } = action.payload;

            const prevData = state.completedAssignmentsByPage?.data || [];

            let page = state.completedAssignmentsByPage?.page || 0;

            if (!error) {
                page += 1;
            }

            return {
                ...state,
                completedAssignmentsByPage: {
                    data: [
                        ...prevData,
                        ...data,
                    ],
                    page,
                    error,
                    hasMore,
                    isLoading: false,
                },
            };
        }

        case types.CLEAR_COMPLETED_ASSIGNMENTS_BY_PAGE: {
            return {
                ...state,
                completedAssignmentsByPage: {},
            };
        }

        /* --- */

        case types.SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID_LOADING: {
            const { classId } = action.payload;

            if (!classId) {
                return state;
            }

            const incompleteAssignmentsByClassId = { ...state.incompleteAssignmentsByClassId };
            const prev = incompleteAssignmentsByClassId[classId] || {};

            incompleteAssignmentsByClassId[classId] = {
                ...prev,
                error: "",
                isLoading: true,
                isLoaded: false,
            };

            return {
                ...state,
                incompleteAssignmentsByClassId,
            };
        }

        case types.SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID: {
            const {
                classId,
                data,
                hasMore,
                error,
            } = action.payload;

            if (!classId) {
                return state;
            }

            const incompleteAssignmentsByClassId = { ...state.incompleteAssignmentsByClassId };
            const prev = incompleteAssignmentsByClassId[classId] || {};

            let newData = prev.data || [];
            let page = prev.page || 0;

            if (!error) {
                newData = [
                    ...newData,
                    ...data,
                ];
                page += 1;
            }

            return {
                ...state,
                incompleteAssignmentsByClassId: {
                    ...state.incompleteAssignmentsByClassId,
                    [classId]: {
                        data: newData,
                        error,
                        page,
                        hasMore,
                        isLoading: false,
                        isLoaded: true,
                    },
                },
            };
        }

        /* --- */

        case types.SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID_LOADING: {
            const { classId } = action.payload;

            if (!classId) {
                return state;
            }

            const completedAssignmentsByClassId = { ...state.completedAssignmentsByClassId };
            const prev = completedAssignmentsByClassId[classId] || {};

            completedAssignmentsByClassId[classId] = {
                ...prev,
                error: "",
                isLoading: true,
                isLoaded: false,
            };

            return {
                ...state,
                completedAssignmentsByClassId,
            };
        }

        case types.SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID: {
            const {
                classId,
                data,
                hasMore,
                error,
            } = action.payload;

            if (!classId) {
                return state;
            }

            const completedAssignmentsByClassId = { ...state.completedAssignmentsByClassId };
            const prev = completedAssignmentsByClassId[classId] || {};

            let newData = prev.data || [];
            let page = prev.page || 0;

            if (!error) {
                newData = [
                    ...newData,
                    ...data,
                ];
                page += 1;
            }

            return {
                ...state,
                completedAssignmentsByClassId: {
                    ...state.completedAssignmentsByClassId,
                    [classId]: {
                        data: newData,
                        error,
                        page,
                        hasMore,
                        isLoading: false,
                        isLoaded: true,
                    },
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
