import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    popup: {
        isVisible: false,
        wordAlias: "",
        word: "",
    },

    isWordsLoaded: false,
    words: [],

    wordsById: {},
    wordsByName: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.OPEN_VOCABULARY_WORD_POPUP: {
            const { word } = action.payload;
            const { wordsByName } = state;

            return {
                ...state,
                popup: {
                    ...state.popup,
                    isVisible: true,
                    wordAlias: action.payload.word,
                },
                wordsByName: {
                    ...wordsByName,
                    [word]: {
                        isLoaded: false,
                    },
                },
            };
        }

        case types.OPEN_AND_SET_VOCABULARY_WORD_POPUP: {
            return {
                ...state,
                popup: {
                    ...state.popup,
                    isVisible: true,
                    wordAlias: action.payload.word,
                    word: action.payload.word,
                },
            };
        }

        case types.CLOSE_VOCABULARY_WORD_POPUP: {
            return {
                ...state,
                popup: {
                    ...initialState.popup,
                },
            };
        }

        case types.SET_VOCABULARY_WORD_POPUP_ALIAS_WORD: {
            if (state.popup.wordAlias !== action.payload.wordAlias) {
                return state;
            }

            return {
                ...state,
                popup: {
                    ...state.popup,
                    wordAlias: action.payload.wordAlias,
                    word: action.payload.word,
                },
            };
        }

        /* --- */

        case types.SET_VOCABULARY_WORDS: {
            return {
                ...state,
                isWordsLoaded: true,
                words: action.payload.words,
            };
        }

        case types.SET_VOCABULARY_WORD_LOADING: {
            const { word } = action.payload;

            const { wordsByName } = state;

            return {
                ...state,
                wordsByName: {
                    ...wordsByName,
                    [word]: {
                        isLoaded: false,
                    },
                },
            };
        }

        case types.SET_VOCABULARY_WORD_ERROR: {
            const { word, error } = action.payload;

            const { wordsByName } = state;
            const wordValue = wordsByName[word] || {};

            return {
                ...state,
                wordsByName: {
                    ...wordsByName,
                    [word]: {
                        ...wordValue,
                        isLoaded: true,
                        error,
                    },
                },
            };
        }

        case types.SET_VOCABULARY_WORD: {
            const { word, definitions } = action.payload;

            const { wordsById, wordsByName } = state;

            const newWord = {
                isLoaded: true,
                word,
                definitions,
            };

            return {
                ...state,
                wordsById: {
                    ...wordsById,
                    [word.id]: newWord,
                },
                wordsByName: {
                    ...wordsByName,
                    [word.word.toLowerCase()]: newWord,
                },
            };
        }

        case types.CLEAR_VOCABULARY_WORD_BY_NAME: {
            const { word } = action.payload;

            const { wordsByName } = state;

            delete wordsByName[word];

            return {
                ...state,
                wordsByName: {
                    ...wordsByName,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
