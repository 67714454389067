import User from "@/base/project/user.js";
import Grades from "@/base/project/grades.js";

import text from "@/base/text/index.js";


const sendSearchEvent = (services, params = {}) => {
    const {
        grade,
        search,
        isMini,
    } = params;

    return (dispatch, getState) => {
        if (isMini) {
            services.events.searchMini.onSearch({
                grades: grade,
                word: search,
            });
            return;
        }

        const state = getState();
        const { session } = state.user;

        if (User.hasRoleTeacher(state.user)) {
            services.events.teacher.onSearch({ session });
            return;
        }

        services.events.search.onSearch({
            session,
            userId: state.user.userId,
            grades: grade,
            word: search,
        });
    };
};

const searchByParams = (services, params) => {
    const { api, actions } = services;
    const {
        search,
        grade,
        categories,
        standards,
        orderBy,
        contentType,
        contentTags,
        page,
        isMini,
    } = params;

    return async (dispatch, getState) => {
        let session = "";
        let showExcludedItems = false;

        if (!isMini) {
            const state = getState();
            session = state.user.session || "";
            showExcludedItems = state.user.user.showExcludedItemsInSearch || false;
        }

        dispatch(sendSearchEvent(services, {
            search,
            grade,
            isMini,
        }));

        if (search) {
            dispatch(actions.search.setSearchByKeywordLoading({
                keyword: search,
            }));
        } else {
            dispatch(actions.search.setSearchEmptyLoading());
        }

        const res = await api.search.all({
            session,
            search,
            page,
            orderBy,
            categories: categories.join(",") || "all",
            standards: standards.join(",") || "all",
            contentTags: contentTags.join(",") || "",
            type: contentType,
            grades: Grades.convertGradesToQueryString(grade),
            showExcludedItems,
        });

        let resultsPerPage = 0;
        let totalResults = 0;
        let results = [];
        let error = "";

        if (res.ok) {
            resultsPerPage = res.perPage;
            totalResults = res.totalResults;
            results = res.results;
        } else {
            error = res.error || text.error;
        }

        const data = {
            resultsPerPage,
            totalResults,
            results,
            error,
            selectedPage: page,
        };

        if (search) {
            dispatch(actions.search.setSearchByKeyword({
                keyword: search,
                data,
            }));
            return;
        }

        dispatch(actions.search.setSearchEmptyData({
            data,
        }));
    };
};

const loadStandards = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.search.getStandards({
            session: state.user.session || "",
            state: state.user.user.subdivision,
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.search.setSearchStandards({
            standards: res.data,
        }));
    };
};

const loadCategories = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.search.categories({
            session: state.user.session || "",
        });

        if (!res.ok) {
            return;
        }

        dispatch(actions.search.setSearchCategories({
            categories: res.categories,
        }));
    };
};

const loadContentTags = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.search.setSearchContentTagsLoading());

        const res = await api.search.getContentTags({
            session: state.user.session || "",
        });

        let error = "";
        let data = [];

        if (res.ok) {
            data = res.contentTags || [];
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.search.setSearchContentTags({
            data,
            error,
        }));
    };
};

export default {
    searchByParams,
    loadStandards,
    loadCategories,
    loadContentTags,
};
