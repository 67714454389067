import Classes from "@/base/project/classes.js";
import Schools from "@/base/project/schools.js";

import text from "@/base/text/index.js";


const loadClassTeachers = (services, params) => {
    const { api, actions } = services;
    const { classId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.teacher.setTeacherClassesTeachersByIdLoading({
            classId,
        }));

        const res = await api.classes.getClassTeachers({
            session: state.user.session,
            classId,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherClassesTeachersById({
            classId,
            data,
            error,
        }));
    };
};

const reloadClassTeachers = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        const classId = state.teacher.selectedClassId;

        if (!classId || classId === "-1") {
            return;
        }

        dispatch(actions.teacher.setTeacherClassesTeachersByIdLoading({
            classId,
        }));

        const res = await api.classes.getClassTeachers({
            session: state.user.session,
            classId,
        });

        let data = null;
        let error = "";

        if (res.ok) {
            data = res.data || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacher.setTeacherClassesTeachersById({
            classId,
            data,
            error,
        }));
    };
};

const deleteTeacherFromClass = (services, params) => {
    const { api } = services;
    const { teacherId, classId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.classes.deleteTeacherFromClass({
            session: state.user.session,
            teacherId,
            classId,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const deleteClass = (services, params) => {
    const { api } = services;
    const { classId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.classes.deleteClass({
            session: state.user.session,
            classId,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const addStudentToClass = (services, params) => {
    const { api } = services;
    const {
        classId,
        firstname,
        lastname,
        email,
        grade,
        addNewOnDuplicate,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.classes.addStudent({
            session: state.user.session,
            classId,
            firstname,
            lastname,
            email,
            grade,
            addNewOnDuplicate,
        });

        let error = "";
        let student = {};

        let isFullNameDuplicate = false;
        let isEmailDuplicate = false;
        let isInAnotherClass = false;
        let isAlreadyInThisClass = false;

        if (!res.ok) {
            const details = res.details || {};

            student = details?.student || {};

            isFullNameDuplicate = details.isFullNameDuplicate || false;
            isEmailDuplicate = details.isEmailDuplicate || false;
            isInAnotherClass = details.isInAnotherClass || false;
            isAlreadyInThisClass = details.isAlreadyInThisClass || false;

            error = res.error || text.error;
        }

        return {
            error,
            student,
            isFullNameDuplicate,
            isEmailDuplicate,
            isInAnotherClass,
            isAlreadyInThisClass,
        };
    };
};

const setupClass = (services, params) => {
    const { api } = services;
    const {
        selectedSchoolType,
        selectedDistrictId,
        selectedCity,
        country,
        subdivision,
        district,
        city,
        schoolName,
        department,
        className,
        grades,
        endDate,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const schools = Schools.isPublicSchoolType(selectedSchoolType)
            ? state.geo.schoolsByDistrictId?.[selectedDistrictId] || []
            : state.geo.schoolsByCity?.[selectedCity] || [];

        const res = await api.classes.setupTeacherClass({
            session: state.user.session,
            schoolType: selectedSchoolType,
            country,
            subdivision,
            district,
            city,
            schoolName,
            schoolId: Schools.getSchoolIdByName(schools, schoolName),
            department,
            className,
            grades,
            endDate,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const setStudentProgressNotificationViewed = (services) => {
    const { api, actions } = services;

    return async (dispatch, getState) => {
        const state = getState();

        if (!state.user.session) {
            return;
        }

        const classId = state.teacher.selectedClassId || "";
        const classes = state.teacher.classes || [];

        const selectedClass = Classes.getClassById(classes, classId);

        if (!selectedClass || !selectedClass.hasNewProgressReport) {
            return;
        }

        const res = await api.teacherClass.setProgressReportViewed({
            session: state.user.session,
            classId: selectedClass.id,
        });

        if (res.ok) {
            dispatch(actions.teacher.setClassProgressReport({
                classId: selectedClass.id,
                hasNewProgressReport: false,
            }));
        }
    };
};

export default {
    loadClassTeachers,
    reloadClassTeachers,
    deleteTeacherFromClass,
    addStudentToClass,
    deleteClass,
    setupClass,
    setStudentProgressNotificationViewed,
};
