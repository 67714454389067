import React from "react";

import PopupCommon from "@/base/components/popup-common/index.js";

import EventsWrapper from "@/base/components/events-wrapper/index.js";
import Close from "@/base/components/close/index.js";

import styles from "./styles.module.css";


const PopupText = (props) => {
    const popupClassName = [
        styles.overlappedPopup,
        props.popupClasses,
    ].join(" ");

    return (
        <PopupCommon
            popupClasses={popupClassName}
            onClose={props.onClose}
            hideBodyScrollOnOpen={props.hideScrollbar}
        >
            <EventsWrapper className={styles.textPopup}>
                <div className={styles.textContainer}>
                    <Close
                        onClose={props.onClose}
                        className={styles.close}
                    />
                    <div>
                        {props.lines.map((line) => <div>{line}</div>)}
                    </div>
                </div>
            </EventsWrapper>
        </PopupCommon>
    );
};

PopupText.defaultProps = {
    lines: [],
    popupClasses: "",
    hideScrollbar: true,
    onClose: () => { },
};

export default PopupText;
