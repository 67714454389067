import TeacherClasses from "@/base/project/teacher-classes.js";

import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    achievementsByClass: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_TEACHER_STUDENTS_ACHIEVEMENTS_LOADING: {
            const { classId } = action.payload;
            const classData = state.achievementsByClass[classId] || {};

            return {
                ...state,
                achievementsByClass: {
                    ...state.achievementsByClass,
                    [classId]: {
                        ...classData,
                        isLoading: true,
                        students: [],
                    },
                },
            };
        }

        case types.SET_TEACHER_STUDENTS_ACHIEVEMENTS: {
            const { classId, students } = action.payload;
            const classData = state.achievementsByClass[classId] || {};

            return {
                ...state,
                achievementsByClass: {
                    ...state.achievementsByClass,
                    [classId]: {
                        ...classData,
                        isLoading: false,
                        students: students || [],
                    },
                },
            };
        }

        case types.UPDATE_TEACHER_STUDENTS_ACHIEVEMENTS: {
            const { classId, students } = action.payload;
            const classData = state.achievementsByClass[classId] || {};
            const oldStudents = classData?.students || [];

            const newStudents = TeacherClasses.updateStudentsAchievements(
                oldStudents,
                students,
            );

            return {
                ...state,
                achievementsByClass: {
                    ...state.achievementsByClass,
                    [classId]: {
                        ...classData,
                        students: newStudents,
                    },
                },
            };
        }

        case types.CLEAR_UPDATED_STATUS_TEACHER_STUDENTS_ACHIEVEMENTS: {
            const { classId, students } = action.payload;
            const classData = state.achievementsByClass[classId] || {};
            const oldStudents = classData?.students || [];

            const newStudents = TeacherClasses.clearStudentsAchievementsUpdatedStatus(
                oldStudents,
                students,
            );

            return {
                ...state,
                achievementsByClass: {
                    ...state.achievementsByClass,
                    [classId]: {
                        ...classData,
                        students: newStudents,
                    },
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
