import React, { useState } from "react";
import { useSelector } from "react-redux";

import User from "@/base/project/user.js";
import Theme from "@/base/project/theme.js";

import UserMainFooter from "@/base/components/user-main-footer/index.js";
import FivesyBanner from "@/base/components/fivesy-banner/index.js";

import PopupFivesyContainer from "@/app/containers/popup-fivesy/index.js";
import PopupFivesyContainerV2 from "@/app/containers/popup-fivesy-v2/index.js";

import settings from "@/app/settings";

import styles from "./styles.module.css";


const storeSelector = (state) => ({
    user: state.user.user,
    theme: state.app.theme.theme,
});

const UserFooter = () => {
    const [isVisibleFivesy, setIsVisibleFivesy] = useState(false);

    const store = useSelector(storeSelector);
    const isStudent = User.hasRoleStudent(store.user);
    const isTeacher = User.hasRoleTeacher(store.user);
    const isDistrictAdmin = User.hasRoleDistrictAdmin(store.user);

    const isHighContrast = Theme.isHighContrast(store.theme);

    const onOpenFivesy = () => {
        setIsVisibleFivesy(true);
    };

    const onCloseFivesy = () => {
        setIsVisibleFivesy(false);
    };

    const renderFivesyBanner = () => {
        if (!isStudent && !isTeacher && !isDistrictAdmin) {
            return null;
        }

        return (
            <div className={styles.fivesyBanner}>
                <FivesyBanner
                    onClick={onOpenFivesy}
                    isHighContrast={isHighContrast}
                />
            </div>
        );
    };

    const renderFivesy = () => {
        if (!isVisibleFivesy) {
            return null;
        }

        if (settings.features.FIVESY_STATS) {
            return (
                <PopupFivesyContainerV2
                    onClose={onCloseFivesy}
                />
            );
        }

        return (
            <PopupFivesyContainer
                onClose={onCloseFivesy}
            />
        );
    };

    return (
        <>
            {renderFivesy()}
            {renderFivesyBanner()}

            <UserMainFooter
                linkPrivacyPolicy={settings.landingSite.privacyPolicy}
                linkTermsOfUse={settings.landingSite.termsOfUse}
                linkSupport={settings.landingSite.support}
                copyright={settings.copyright}
            />
        </>
    );
};

export default UserFooter;
