import React, { useState } from "react";

import IconPlayerHeadphone from "@/base/icons/player-headphone/index.js";

import IconClickable from "@/base/components/icon-clickable/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import PlayerAudioV2 from "@/base/components/player-audio-v2/index.js";
import PopupWindow from "@/base/components/popup-window/index.js";

import DailyJuiceStory from "@/base/business/daily-juice-story/index.js";

import styles from "./styles.module.css";


const PopupStory = (props) => {
    const [isPlayerVisible, setIsPlayerVisible] = useState(false);

    const hasVideo = !!props?.story?.featuredVideo?.url;

    /* --- */

    const renderTitle = () => {
        if (!props.story) {
            return "Story";
        }

        return props.story.title;
    };

    const renderContent = () => {
        if (!props.story) {
            return (
                <RequestLoader />
            );
        }

        return (
            <div className={styles.content}>
                <DailyJuiceStory
                    storyIndex={null}
                    story={props.story}
                    dimensions={props.dimensions}
                    videoCaptionSrc={props.videoCaptionSrc}
                    onImageClick={props.onImageClick}
                    onWordClick={props.onWordClick}
                    onExtraJuiceWordClick={props.onOpenExtraJuice}
                    isDefaultVideo={props.isDefaultVideo}
                    withListenButton={false}
                    withContentTags={props.withContentTags}
                />
            </div>
        );
    };

    const renderPopupControl = () => {
        if (!props.story || hasVideo) {
            return [];
        }

        const audioPlayer = (
            <IconClickable
                className={styles.buttonListen}
                onClick={() => {
                    if (!isPlayerVisible) {
                        props.storyCallbacks.onLoadingStory(props.storyId);
                        setIsPlayerVisible(true);
                    }
                }}
            >
                <IconPlayerHeadphone
                    title="Play story"
                    isOrange
                />
            </IconClickable>
        );

        return [audioPlayer];
    };

    const renderPlayer = () => {
        if (!isPlayerVisible) {
            return null;
        }

        const trackId = props.story.id;
        const audio = props.audioState?.[trackId] || {};

        return (
            <PlayerAudioV2
                image={props.story?.featuredImage?.url || ""}
                title={props.story?.title || "Title"}
                category={props.story?.categoryName || "Story"}
                audio={audio}
                playback={props.audioPlayback}
                error={audio.error || ""}
                onFirstPlay={() => {
                    props.storyCallbacks.onFirstPlay(trackId);
                }}
                onRetryPlay={() => {
                    props.storyCallbacks.onRetryPlay(trackId);
                }}
                onPlay={() => {
                    props.storyCallbacks.onPlay(trackId);
                }}
                onPause={() => {
                    props.storyCallbacks.onPause(trackId);
                }}
                onRewind={() => {
                    props.storyCallbacks.onRewind(trackId);
                }}
                onForward={() => {
                    props.storyCallbacks.onForward(trackId);
                }}
                onChangeRate={(rate) => {
                    props.storyCallbacks.onChangeRate(trackId, rate);
                }}
                onClose={() => {
                    setIsPlayerVisible(false);
                    props.storyCallbacks.onStop(trackId);
                }}
                isMobile={props.isMobile}
            />
        );
    };

    return (
        <PopupWindow
            title={renderTitle()}
            rightControl={renderPopupControl()}
            player={renderPlayer()}
            onClose={props.onClose}
            hideScrollbar={props.hideScrollbar}
        >
            {renderContent()}
        </PopupWindow>
    );
};

PopupStory.defaultProps = {
    dimensions: {},
    story: null,
    videoCaptionSrc: "",
    audioState: {},
    audioPlayback: {},
    storyCallbacks: {
        onLoadingStory: () => { },
        onFirstPlay: () => { },
        onPlay: () => { },
        onPause: () => { },
        onRewind: () => { },
        onForward: () => { },
        onChangeRate: () => { },
        onStop: () => { },
    },
    onImageClick: () => { },
    onWordClick: () => { },
    onOpenExtraJuice: () => { },
    onClose: () => { },

    withContentTags: false,
    hideScrollbar: true,
    isMobile: false,
    isDefaultVideo: true,
};

export default PopupStory;
