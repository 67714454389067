import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import User from "@/base/project/user.js";
import Urls from "@/base/project/urls.js";

import array from "@/base/lib/array.js";

import text from "@/base/text/index.js";

import navigationSelectors from "@/base/store-selectors/navigation.js";

import useStudentInfoPopup from "@/base/hooks/use-student-info-popup/index.js";

import TeacherPopupStudentInfo from "@/app/containers/teacher-popup-student-info/index.js";
import TeacherPopupStudentsRemove from "@/app/containers/teacher-popup-students-remove/index.js";

import app from "@/app/app.js";


const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
    location: state.navigation.location,
    urlParams: navigationSelectors.classStudentParams(state),

    session: state.user.session,
    user: state.user.user,

    info: state.info,
    siteDate: state.info.siteDate,

    teacher: state.teacher,
    juices: state.juices,
    progressByClassId: state.teacherStudentsProgress.progressByClassId,
});

const ClassStudentInfoPopup = () => {
    const [removeStudentsState, setRemoveStudentsState] = useState({
        isVisiblePopup: false,
        studentId: null,
    });

    const navigate = useNavigate();

    const store = useSelector(storeSelector);

    const studentInfoPopup = useStudentInfoPopup();

    const isMobile900 = store.dimensions.width < 900;
    const isMobile500 = store.dimensions.width < 500;

    const urlView = useMemo(() => {
        const pathname = store.location?.pathname || "";

        return {
            isAdmin: Urls.isClassAdmin(pathname),
            isDistrictAdmin: Urls.isClassDistrictAdmin(pathname),
        };
    }, [store.location?.pathname]);

    const classId = useMemo(() => {
        if (urlView.isAdmin || urlView.isDistrictAdmin) {
            return parseInt(store.urlParams.classId, 10);
        }

        return store.teacher.selectedClassId;
    }, [
        store.teacher.selectedClassId,
        store.urlParams.classId,
        urlView.isAdmin,
        urlView.isDistrictAdmin,
    ]);

    /* --- */

    const getDateTo = () => {
        if (store.urlParams.isDailyJuiceView) {
            return store.urlParams.dateTo;
        }

        return store.siteDate || "";
    };

    const getClassStudentsScores = () => {
        const { dateFrom, dateTo } = store.urlParams;

        const range = `${dateFrom}-${dateTo}`;

        if (store.urlParams.isDailyJuiceView) {
            const data = store.teacher.classesScoresByDailyJuices?.[classId]?.[range] || {};
            return data?.data?.students || [];
        }

        if (store.urlParams.isAssignmentsView) {
            const data = store.teacher.classesScoresByAssignments?.[classId]?.[range] || {};
            return data?.data?.students || [];
        }

        if (store.urlParams.isProgressView) {
            const data = store.progressByClassId?.[classId] || {};

            return (data?.students || []).map((student) => {
                // NOTE: it fix API inconsistency
                return {
                    ...student,
                    fullName: User.getFullName(student),
                };
            });
        }

        return [];
    };

    const getStudentData = () => {
        const { student, error, isLoaded } = studentInfoPopup.state;

        return {
            student,
            error,
            isLoaded,
        };
    };

    /* --- */

    const loadClasses = () => {
        if (urlView.isAdmin || urlView.isDistrictAdmin) {
            app.actions.districtAdmin.schools.loadDepartmentClasses({
                departmentId: store.urlParams.department,
                schoolId: store.urlParams.school,
            });
            return;
        }

        app.actions.teacher.classes.loadAllClasses();
    };

    const onLoadStudentInfo = async (studentId) => {
        studentInfoPopup.setLoading();

        const res = await app.services.api.students.getStudentInfoByClassId({
            session: store.session,
            studentId,
            classId,
        });

        if (res.ok) {
            studentInfoPopup.setStudent(res.student);
        } else {
            studentInfoPopup.setStudentError(res.error || text.error);
        }
    };

    const reloadStudentInfo = () => {
        onLoadStudentInfo(studentInfoPopup.state.studentId);
    };

    /* --- */

    // TODO: if-else problems
    const onClose = () => {
        studentInfoPopup.close();

        const { dateFrom, dateTo, view } = store.urlParams;

        const pathEnd = store.urlParams.isProgressView
            ? `${view}`
            : `${dateFrom}/${dateTo}/${view}`;

        if (urlView.isAdmin) {
            const {
                district,
                school,
                department,
            } = store.urlParams;

            navigate(`/admin/class/${district}/${school}/${department}/${classId}/${pathEnd}`);
            return;
        }

        if (urlView.isDistrictAdmin) {
            const { school, department } = store.urlParams;

            navigate(`/district-admin/class/${school}/${department}/${classId}/${pathEnd}`);
            return;
        }

        navigate(`/class/${pathEnd}`);
    };

    const onMenuChange = (page) => {
        const {
            dateFrom,
            dateTo,
            studentId,
            view,
        } = store.urlParams;

        const pathEnd = store.urlParams.isProgressView
            ? `${view}/${studentId}/${page}`
            : `${dateFrom}/${dateTo}/${view}/${studentId}/${page}`;

        if (urlView.isAdmin) {
            const {
                district,
                school,
                department,
            } = store.urlParams;

            navigate(`/admin/class/${district}/${school}/${department}/${classId}/${pathEnd}`);
            return;
        }

        if (urlView.isDistrictAdmin) {
            const {
                school,
                department,
            } = store.urlParams;

            navigate(`/district-admin/class/${school}/${department}/${classId}/${pathEnd}`);
            return;
        }

        navigate(`/class/${pathEnd}`);
    };

    const onSelectStudent = (studentId) => {
        const {
            dateFrom,
            dateTo,
            studentViewTab,
            view,
        } = store.urlParams;

        const pathEnd = store.urlParams.isProgressView
            ? `${view}/${studentId}/${studentViewTab}`
            : `${dateFrom}/${dateTo}/${view}/${studentId}/${studentViewTab}`;

        if (urlView.isAdmin) {
            const {
                district,
                school,
                department,
            } = store.urlParams;

            navigate(`/admin/class/${district}/${school}/${department}/${classId}/${pathEnd}`);
            return;
        }

        if (urlView.isDistrictAdmin) {
            const {
                school,
                department,
            } = store.urlParams;

            navigate(`/district-admin/class/${school}/${department}/${classId}/${pathEnd}`);
            return;
        }

        navigate(`/class/${pathEnd}`);
    };

    const onClosePopupStudentsRemove = () => {
        setRemoveStudentsState({
            isVisiblePopup: false,
            studentId: null,
        });
    };

    const onOpenRemoveStudentsPopup = (studentId) => {
        setRemoveStudentsState({
            isVisiblePopup: true,
            studentId,
        });
    };

    /* --- */

    useEffect(() => {
        if (!store.urlParams.studentId) {
            return;
        }

        const studentId = parseInt(store.urlParams.studentId, 10);

        if (Number.isNaN(studentId)) {
            onClose();
            return;
        }

        studentInfoPopup.open(studentId);

        if (classId !== -1) {
            onLoadStudentInfo(studentId);
        }
    }, [
        store.urlParams.studentId,
        classId,
    ]);

    /* --- */

    const renderStudentRemovingPopup = (students) => {
        if (!removeStudentsState.isVisiblePopup) {
            return null;
        }

        const selectedStudents = [];

        const student = array.findOneById(students, removeStudentsState.studentId);

        if (student) {
            selectedStudents.push(student);
        }

        return (
            <TeacherPopupStudentsRemove
                students={selectedStudents}
                onCloseWithFinishedStatus={() => {
                    loadClasses();
                    onClosePopupStudentsRemove();
                    onClose();
                }}
                onClose={onClosePopupStudentsRemove}
            />
        );
    };

    /* --- */

    if (!studentInfoPopup.state.isOpen) {
        return null;
    }

    const students = getClassStudentsScores();
    const studentData = getStudentData();

    return (
        <>
            {renderStudentRemovingPopup(students)}

            <TeacherPopupStudentInfo
                classId={classId}
                /* --- */
                studentId={studentInfoPopup.state.studentId}
                student={studentData.student}
                studentError={studentData.error}
                onReloadStudent={reloadStudentInfo}
                isStudentLoaded={studentData.isLoaded}
                /* --- */
                defaultSelectedMenu={store.urlParams.studentViewTab}
                students={students}
                dateFrom={store.urlParams.dateFrom}
                dateTo={getDateTo()}
                onMenuChange={onMenuChange}
                onSelectStudent={onSelectStudent}
                onRemoveStudent={() => {
                    onOpenRemoveStudentsPopup(studentInfoPopup.state.studentId);
                }}
                onUpdate={() => {
                    reloadStudentInfo();
                    loadClasses();
                }}
                onClose={onClose}
                hideArrows={isMobile500}
                isMobile={isMobile900}
                isCards={isMobile900}
            />
        </>
    );
};

export default ClassStudentInfoPopup;
