import dateMoment from "@/base/lib/date-moment.js";
import sections from "@/app/settings/sections.js";


const currentFullYear = dateMoment.getFullYear();

export default {
    features: FEATURES, // eslint-disable-line no-undef

    appName: "The Juice",
    copyright: `The Juice ${currentFullYear}`,

    previewCode: "ba6q7efg2a",

    sectionsByRoles: sections,

    mobileHeaderHiddenPaths: [
        "/daily-juices/:juiceId/:storyId",
    ],

    calendarDates: {
        min: dateMoment.getDaysBefore(365),
        max: dateMoment.getDaysAfter(60),
    },

    password: {
        minLength: 8,
    },

    signUpByCodePath: "/user/sign-up",

    google: {
        classroomShareUrl: "//classroom.google.com/u/0/share",
    },

    landingSite: {
        domain: "//thejuicelearning.com",
        support: "//thejuicelearning.com/support",
        privacyPolicy: "//thejuicelearning.com/privacy-policy",
        termsOfUse: "//thejuicelearning.com/terms-of-use",
        routeSignUp: "/signup",
        routeSignUpFormLink: "/signup/request-quote",
        routePrivacyPolicy: "/privacy-policy",
        routeSupport: "/support",
    },

    supportEmail: "customersupport@thejuice.news",
};
