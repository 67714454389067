import React, { useRef } from "react";

import IconClose from "@/base/icons/close/index.js";

import IconClickable from "@/base/components/icon-clickable/index.js";
import PopupCommon from "@/base/components/popup-common/index.js";
import ImageWithZoom from "@/base/components/image-with-zoom/index.js";

import scrollbarStyles from "@/base/components/scrollbar/styles.module.css";
import styles from "./styles.module.css";


const PopupImage = (props) => {
    const backgroundLayout = useRef(null);

    if (!props.image) {
        return null;
    }

    const closePopup = (evt) => {
        if (backgroundLayout
            && backgroundLayout.current === evt.target) {
            props.onClose();
        }
    };

    let close = null;

    if (props.showClose) {
        close = (
            <IconClickable
                className={styles.close}
                onClick={props.onClose}
            >
                <IconClose isBlack />
            </IconClickable>
        );
    }

    const imageScrollerClasses = [
        scrollbarStyles.scrollbar,
        styles.imageScroller,
    ];

    const content = (
        <div
            ref={backgroundLayout}
            className={styles.imagePopup}
            onClick={closePopup}
            onKeyPress={closePopup}
            role="button"
            tabIndex="-1"
        >
            <div className={styles.imageWrapper}>
                {close}
                <div className={imageScrollerClasses.join(" ")}>
                    <ImageWithZoom
                        src={props.image.url}
                        title={props.image.title}
                        className={styles.img}
                        toolsClassName={styles.zoomButtons}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <PopupCommon
            onClose={props.onClose}
            popupClasses={props.popupClasses}
        >
            {content}
        </PopupCommon>
    );
};

PopupImage.defaultProps = {
    image: null,
    showClose: true,
    popupClasses: "",
    onClose: () => { },
};

export default PopupImage;
