import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";

import staticFiles from "@/base/static-files.js";

import classNames from "@/base/lib/class-names.js";
import urls from "@/base/lib/urls.js";

import { getIconByName } from "@/base/icons/index.js";

import User from "@/base/project/user.js";

import IconClose from "@/base/icons/close/index.js";
import IconSignIn from "@/base/icons/sign-in/index.js";
import IconSignOut from "@/base/icons/sign-out/index.js";
import IconQuestion from "@/base/icons/question/index.js";
import IconUser from "@/base/icons/user/index.js";
import IconBookWithHands from "@/base/icons/book-with-hands/index.js";

import DivButton from "@/base/components/div-button/index.js";
import IconClickable from "@/base/components/icon-clickable/index.js";
import WithScroll from "@/base/components/with-scroll/index.js";
import Status from "@/base/components/status/index.js";

import AsideNavButton from "./button.js";
import styles from "./styles.module.css";


const AsideNav = (props) => {
    const [buildClickCounter, setBuildClickCounter] = useState(0);

    const onBuildClick = useCallback(() => {
        if (buildClickCounter === 5) {
            props.onGoToTelemetry();
            setBuildClickCounter(0);
            return;
        }

        setBuildClickCounter((prev) => prev += 1);
    }, [buildClickCounter]);

    const onSignOut = () => {
        props.onClose();
        props.onSignOut();
    };

    const renderStatus = () => {
        return (
            <Status
                isGreen={props.isSocketMonitorOnline}
            />
        );
    };

    const renderSectionWithIcon = (params) => {
        const {
            to,
            className,
            name,
            isIconOrange,
        } = params;

        const linkClassName = classNames({
            [styles.sectionWithIcon]: true,
            [className]: !!className,
        });

        let icon = null;

        if (params.icon) {
            icon = (
                <params.icon
                    className={styles.sectionIcon}
                    isOrange={!!isIconOrange}
                />
            );
        }

        return (
            <Link
                to={to}
                className={linkClassName}
                onClick={props.onClose}
            >
                {icon}
                <div className={styles.sectionName}>
                    {name}
                </div>
            </Link>
        );
    };

    const renderLink = (to, name, iconName) => {
        let isSelected = false;

        if (to === "/") {
            isSelected = props.pathname === to;
        } else {
            isSelected = props.pathname.indexOf(to) === 0;
        }

        const IconComponent = getIconByName(iconName);

        const className = classNames({
            [styles.sectionWithIcon]: true,
            [styles.sectionWithIconAndBorder]: true,
            [styles.sectionWithIconSelected]: isSelected,
        });

        return renderSectionWithIcon({
            to,
            className,
            icon: IconComponent || null,
            name,
            isIconOrange: isSelected,
        });
    };

    const renderSupportLink = () => {
        if (!props.supportLink) {
            return null;
        }

        return (
            <AsideNavButton
                label="Support"
                icon={IconQuestion}
                onClick={() => {
                    urls.openUrl(props.supportLink);
                }}
            />
        );
    };

    const renderSections = () => {
        if (!props.user.userId) {
            return null;
        }

        const homeLink = props.isVisibleHomeLink
            ? renderLink("/", "Home", "home")
            : null;

        const sections = [];

        User.getNavSections(props.user, props.sectionsByRoles).forEach((sec) => {
            sections.push(renderLink(sec.to, sec.name, sec.icon));
        });

        return (
            <>
                {homeLink}
                {sections}
            </>
        );
    };

    const renderResourcesButton = () => {
        if (!props.isVisibleResources) {
            return null;
        }

        return (
            <AsideNavButton
                label="Resources"
                icon={IconBookWithHands}
                onClick={props.onResourcesClick}
            />
        );
    };

    const renderUser = () => {
        if (!props.user.userId) {
            return (
                <>
                    {renderSectionWithIcon({
                        to: "/sign-in",
                        icon: IconSignIn,
                        name: "Sign In",
                    })}
                    {renderSupportLink()}
                </>
            );
        }

        return (
            <>
                {renderSectionWithIcon({
                    to: "/user/profile",
                    icon: IconUser,
                    name: props.user.fullName,
                })}
                {renderResourcesButton()}
                {renderSupportLink()}
                <AsideNavButton
                    label="Sign Out"
                    icon={IconSignOut}
                    onClick={onSignOut}
                />
            </>
        );
    };

    /* eslint-disable */
    const layout = (
        <div
            className={styles.asideLayout}
            onClick={props.onClose}
            onKeyPress={props.onClose}
            tabIndex="-1"
            role="button"
        />
    );
    /* eslint-enable */

    return (
        <div className={styles.asideNav}>
            {layout}
            <aside className={styles.aside}>
                <div className={styles.header}>
                    <IconClickable
                        onClick={props.onClose}
                        className={styles.close}
                    >
                        <IconClose isBlack />
                    </IconClickable>

                    <div className={styles.headerName}>
                        <img
                            src={staticFiles.juiceHomeLogo}
                            alt={props.name}
                            className={styles.logoImg}
                        />
                    </div>
                </div>

                <WithScroll>
                    <div>
                        {renderSections()}
                    </div>
                    <div className={styles.sectionGroupWithBottomBorder}>
                        {renderUser()}
                    </div>
                </WithScroll>

                <DivButton
                    className={styles.sysinfo}
                    onClick={onBuildClick}
                >
                    {renderStatus()}
                    {`Build: ${props.version}`}
                </DivButton>
            </aside>
        </div>
    );
};

AsideNav.defaultProps = {
    name: "",
    version: "",
    user: {},
    pathname: "",
    sectionsByRoles: {},
    supportLink: "",
    onResourcesClick: () => { },
    onSignOut: () => { },
    onClose: () => { },
    onGoToTelemetry: () => { },
    isVisibleResources: false,
    isVisibleHomeLink: true,
    isSocketMonitorOnline: false,
};

export default AsideNav;
