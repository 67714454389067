const loadStateStandards = (services, params) => {
    const { api, actions } = services;

    return async (dispatch) => {
        const res = await api.standards.getStandardsByState({
            state: params.subdivision,
        });

        if (res.ok) {
            dispatch(actions.standards.setStateStandardsByState({
                stateName: params.subdivision,
                standards: res.standards,
            }));
        }
    };
};

export default {
    loadStateStandards,
};
