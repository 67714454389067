// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Vw3gEWDvrgPZgYZcgm4y {\n    position: relative;\n\n    min-width: 30rem;\n    max-width: 50rem;\n\n    padding: 2.4rem;\n\n    display: flex;\n    justify-content: center;\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 1rem;\n    background: var(--text-primary-bg-color);\n}\n\n.SWreN0LLUj_YIWl6XIf0 {\n    z-index: var(--z-index-popup-common);\n}\n\n.Bdgjg8zhZdNhrvWg3byR {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-loading/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,gBAAgB;IAChB,gBAAgB;;IAEhB,eAAe;;IAEf,aAAa;IACb,uBAAuB;;IAEvB,gDAAgD;IAChD,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf","sourcesContent":[".popupLoading {\n    position: relative;\n\n    min-width: 30rem;\n    max-width: 50rem;\n\n    padding: 2.4rem;\n\n    display: flex;\n    justify-content: center;\n\n    border: 0.1rem solid var(--border-default-color);\n    border-radius: 1rem;\n    background: var(--text-primary-bg-color);\n}\n\n.overlappedPopup {\n    z-index: var(--z-index-popup-common);\n}\n\n.close {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupLoading": "Vw3gEWDvrgPZgYZcgm4y",
	"overlappedPopup": "SWreN0LLUj_YIWl6XIf0",
	"close": "Bdgjg8zhZdNhrvWg3byR"
};
export default ___CSS_LOADER_EXPORT___;
