export default (apiURL, methods) => {
    return {
        getDailyJuiceStory(params = {}) {
            // NOTE: action=get_story_pdf
            const api = `${apiURL}/juice-stories/${params.juiceId}/${params.storyId}/pdf`;

            return methods.get(api, {
                session_id: params.session,
                notes_page: params.includeNotesPage ? 1 : 0,
                vocabulary_definitions: params.includeVocabularyDefinitions ? 1 : 0,
                quiz_question: params.includeQuizQuestion ? 1 : 0,
                extra_juice: params.includeExtraJuice ? 1 : 0,
            });
        },
    };
};
