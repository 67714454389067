export default (apiURL) => {
    return {
        getStudentDailyJuicesResultsExportUrl(params = {}) {
            // NOTE: action=report_daily_juice_activity_by_student_id
            const reqParams = [
                `session_id=${params.session || ""}`,
                `student_id=${params.studentId || ""}`,
                `date_from=${params.dateFrom || ""}`,
                `date_to=${params.dateTo || ""}`,
            ].join("&");

            return `${apiURL}/export/student-daily-juices-results?${reqParams}`;
        },

        getStudentQuizPerformanceExportUrl(params = {}) {
            // NOTE: action=report_student_quiz_performance
            const reqParams = [
                `session_id=${params.session || ""}`,
                `student_id=${params.studentId || ""}`,
                `filter_by=${params.filterBy || ""}`,
                `standard_type=${params.standardType || ""}`,
                `standard_id=${params.standardId || ""}`,
                `range=${params.range || ""}`,
                `date_from=${params.dateFrom || ""}`,
                `date_to=${params.dateTo || ""}`,
            ].join("&");

            return `${apiURL}/export/student-quiz-performance?${reqParams}`;
        },

        getClassScoresByRange(params = {}) {
            // NOTE: action=teacher_report_class_scores_by_range
            const reqParams = [
                `session_id=${params.session || ""}`,
                `sort_by=${params.sortBy || ""}`,
                `date_from=${params.dateFrom || ""}`,
                `date_to=${params.dateTo || ""}`,
            ].join("&");

            return `${apiURL}/export/classes/${params.classId || ""}/daily-juices?${reqParams}`;
        },

        getClassAssignmentsScoresByRange(params = {}) {
            // NOTE: action=teacher_report_class_assignments_scores_by_range
            const reqParams = [
                `session_id=${params.session || ""}`,
                `sort_by=${params.sortBy || ""}`,
                `date_from=${params.dateFrom || ""}`,
                `date_to=${params.dateTo || ""}`,
            ].join("&");

            return `${apiURL}/export/classes/${params.classId || ""}/assignments?${reqParams}`;
        },

        getAssignmentsByStudentId(params = {}) {
            // NOTE: action=teacher_report_assignments_by_student_id
            const reqParams = [
                `session_id=${params.session || ""}`,
                `student_id=${params.studentId || ""}`,
                `page=${params.page || 0}`,
            ].join("&");

            return `${apiURL}/export/assignments-by-student?${reqParams}`;
        },

        getClassProgress(params = {}) {
            // NOTE: action=report_student_progress
            const reqParams = [
                `session_id=${params.session || ""}`,
            ].join("&");

            return `${apiURL}/export/classes/${params.classId || ""}/progress?${reqParams}`;
        },

        getClassScoreReport(params = {}) {
            // NOTE: action=export_score_report
            const reqParams = [
                `session_id=${params.session || ""}`,
                `date_from=${params.dateFrom || ""}`,
                `date_to=${params.dateTo || ""}`,
            ].join("&");

            return `${apiURL}/export/classes/${params.classId || ""}/scores?${reqParams}`;
        },

        getStudentScoreReport(params = {}) {
            // NOTE: action=export_score_report
            const reqParams = [
                `session_id=${params.session || ""}`,
                `user_id=${params.studentId || ""}`,
                `date_from=${params.dateFrom || ""}`,
                `date_to=${params.dateTo || ""}`,
            ].join("&");

            return `${apiURL}/export/classes/${params.classId || ""}/scores?${reqParams}`;
        },

        getSummaryScoreReport(params = {}) {
            // NOTE: action=export_score_report
            const reqParams = [
                `session_id=${params.session || ""}`,
                `date_from=${params.dateFrom || ""}`,
                `date_to=${params.dateTo || ""}`,
                "summaries_only=1",
            ].join("&");

            return `${apiURL}/export/classes/${params.classId || ""}/scores?${reqParams}`;
        },

        getClassesPerformance(params = {}) {
            // NOTE: action=report_teacher_classes_performance
            const reqParams = [
                `session_id=${params.session || ""}`,
                `categories=${params.categories || ""}`,
                `date_from=${params.dateFrom || ""}`,
                `date_to=${params.dateTo || ""}`,
            ].join("&");

            return `${apiURL}/export/classes-performance?${reqParams}`;
        },

        getClassesStatsByLearningStandards(params = {}) {
            // NOTE: action=report_teacher_classes_stats_by_learning_standards
            const reqParams = [
                `session_id=${params.session || ""}`,
                `date_from=${params.dateFrom || ""}`,
                `date_to=${params.dateTo || ""}`,
                `standard_type=${params.standardType || ""}`,
            ].join("&");

            return `${apiURL}/export/classes-stats/by-standards?${reqParams}`;
        },
    };
};
