import React, { useMemo, useCallback } from "react";

import staticFiles from "@/base/static-files.js";

import classNames from "@/base/lib/class-names.js";
import highlight from "@/base/lib/highlight.js";
import parseTags from "@/base/lib/parse-tags.js";
import tags from "@/base/lib/tags.js";

import DailyJuiceStory from "@/base/project/daily-juice-story.js";

import ExtraJuiceButtons from "@/base/components/extra-juice-buttons/index.js";
import JuiceContentPlain from "@/base/components/juice-content-plain/index.js";
import MessageDanger from "@/base/components/message-danger/index.js";
import RequestLoader from "@/base/components/request-loader/index.js";
import Text from "@/base/components/text/index.js";
import WordHighlighted from "@/base/components/word-highlighted/index.js";

import stylesFonts from "@/base/components/fonts/styles.module.css";
import stylesCommon from "@/base/components/juice-content-common/styles.module.css";
import styles from "./styles.module.css";


const parseContent = (paragraph) => {
    let content = parseTags.parse(paragraph).map((line) => {
        if (parseTags.isTag(line)) {
            const wordVal = parseTags.trimTag(line);

            return [
                `<span data-word="word" class="${stylesCommon.vocabWord}">`,
                wordVal,
                "</span>",
            ].join("");
        }

        return line;
    }).join("");

    content = parseTags.parseCurlyBrackets(content).map((line) => {
        if (parseTags.isTagCurlyBrackets(line)) {
            const wordVal = parseTags.trimTagCurlyBrackets(line);

            return [
                `<span data-word="extraJuiceWord" class="${stylesCommon.extraJuiceWord}">`,
                `<img src="${staticFiles.iconOrange}" alt="extra-juice" title="extra-juice"/>`,
                wordVal,
                "</span>",
            ].join("");
        }

        return line;
    }).join("");

    const parsedContent = [];
    const captions = [];

    parseTags.parseShortcode(content).forEach((line) => {
        if (!parseTags.isTagShortcode(line)) {
            parsedContent.push(line);
        } else if (parseTags.isTagShortcodeName(line, "caption")) {
            // TODO:
            const contentLines = parseTags.parse(line.tagContent, "<", "/>");

            if (contentLines.length) {
                let captionText = null;

                if (contentLines[1]) {
                    captionText = [
                        `<div class="${styles.captionText}">`,
                        contentLines[1],
                        "</div>",
                    ].join("");
                }

                const captionContent = [
                    `<div class="${styles.captionImg}">`,
                    contentLines[0] || "",
                    captionText,
                    "</div>",
                ].join("");

                const contentLength = parsedContent.join("").length;

                captions.push({
                    from: contentLength,
                    to: contentLength + captionContent.length,
                });

                parsedContent.push(captionContent);
            }
        } else {
            parsedContent.push(line.tagContent);
        }
    });

    return {
        content: parsedContent.join(""),
        captions,
    };
};

const JuiceContentWithTranslation = (props) => {
    const contentParsed = useMemo(() => {
        return parseContent(props.content);
    }, [props.content]);

    const contentHighlighted = useMemo(() => {
        if (!props.highlight?.isContent) {
            return contentParsed.content || "";
        }

        const word = props?.highlight?.word || "";
        const skipCount = props?.highlight?.skipSameWordCount || 0;

        return highlight.getHighlightedContent({
            content: {
                content: contentParsed.content,
                skips: contentParsed.captions,
            },
            word: {
                word,
                skipCount,
                wrapped: WordHighlighted(word),
            },
        });
    }, [contentParsed, props.highlight]);

    /* --- */

    const onExtraJuiceClick = useCallback(() => {
        props.onExtraJuiceWordClick("story-button");
    }, [props.onExtraJuiceWordClick]);

    const onContentClick = useCallback((evt) => {
        if (evt.target && evt.target.dataset.word) {
            const word = (evt.target.innerText || "").trim();

            if (evt.target.dataset.word === "word") {
                props.onWordClick(word);
            } else if (evt.target.dataset.word === "extraJuiceWord") {
                props.onExtraJuiceWordClick("story");
            }
        }
    }, [props.onWordClick, props.onExtraJuiceWordClick]);

    const renderError = () => {
        if (!props.translationError) {
            return null;
        }

        const blockTextClassName = classNames({
            [styles.blockText]: props.isTranslated && !props.isMobile,
            [styles.blockTextMobile]: props.isMobile,
            [styles.blockTextTranslatedMobile]: props.isMobile,
            [styles.blockError]: true,
        });

        return (
            <div className={blockTextClassName}>
                <MessageDanger>
                    {props.translationError}
                </MessageDanger>
            </div>
        );
    };

    const renderLoader = () => {
        const blockTextClassName = classNames({
            [styles.blockText]: props.isTranslated && !props.isMobile,
            [styles.blockTextMobile]: props.isTranslated && props.isMobile,
            [styles.blockTextTranslatedMobile]: props.isTranslated && props.isMobile,
            [styles.blockLoader]: true,
        });

        return (
            <div className={blockTextClassName}>
                <RequestLoader />
            </div>
        );
    };

    const renderLineTranslated = (line, i) => {
        if (!props.isTranslated) {
            return null;
        }

        if (i === 0 && props.isTranslationLoading) {
            return renderLoader();
        }

        if (i === 0 && props.isTranslated && props.translationError) {
            return renderError();
        }

        if (!props.isTranslated
            || props.translationError
            || props.isTranslationLoading) {
            return null;
        }

        const blockTextClassName = classNames({
            [styles.blockText]: props.isTranslated && !props.isMobile,
            [styles.blockTextMobile]: props.isTranslated && props.isMobile,
            [styles.blockTextTranslatedMobile]: props.isTranslated && props.isMobile,
            [styles.blockTextRight]: props.translationDirection === "rtl",
        });

        return (
            <JuiceContentPlain
                className={blockTextClassName}
                content={line}
                isDyslexic={props.isDyslexic && props.isDyslexicFont}
            />
        );
    };

    const renderContent = () => {
        const contentLines = DailyJuiceStory.getStoryParagraphs(contentHighlighted || "");
        const tLines = DailyJuiceStory.getStoryParagraphs(props.translation?.content || "");

        const blockClassName = classNames({
            [styles.block]: !props.isMobile,
            [styles.blockTranslated]: !props.isMobile && props.isTranslated,
            [styles.blockMobileTranslated]: props.isMobile && props.isTranslated,
        });

        const blockTextClassName = classNames({
            [stylesFonts.content]: true,
            [stylesFonts.contentDyslexic]: props.isDyslexic,
            [stylesCommon.content]: true,
            [styles.blockText]: props.isTranslated && !props.isMobile,
            [styles.blockTextMobile]: props.isTranslated && props.isMobile,
        });

        let skipCounter = 0;

        return contentLines.map((line, i) => {
            let tLine = tLines?.[i - skipCounter] || "";

            const isSkipLine = tags.isEmptyContent(line);

            if (isSkipLine) {
                tLine = "";
                skipCounter += 1;
            }

            return (
                <div className={blockClassName}>
                    <Text
                        className={blockTextClassName}
                        onClick={onContentClick}
                    >
                        {line}
                    </Text>

                    {renderLineTranslated(tLine, i)}
                </div>
            );
        });
    };

    const renderExtraJuices = () => {
        if (props.extraJuices.length === 0) {
            return null;
        }

        return (
            <div className={styles.extraJuices}>
                <ExtraJuiceButtons
                    extraJuices={props.extraJuices}
                    onExtraJuiceClick={onExtraJuiceClick}
                />
            </div>
        );
    };

    const blocksClassName = classNames({
        [styles.blocks]: true,
        [styles.blocksTranslated]: props.isTranslated,
    });

    return (
        <div className={blocksClassName}>
            {renderContent()}
            {renderExtraJuices()}
        </div>
    );
};

JuiceContentWithTranslation.defaultProps = {
    content: "",
    extraJuices: [],
    translation: null,
    translationDirection: "",
    translationError: "",
    highlight: null,

    onWordClick: () => { },
    onExtraJuiceWordClick: () => { },

    isDyslexic: false,
    isDyslexicFont: false,
    isTranslationLoading: false,
    isTranslated: false,
    isMobile: false,
};

export default JuiceContentWithTranslation;
