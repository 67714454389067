import React, { useState } from "react";
import { Formik } from "formik";

import LMS from "@/base/project/lms.js";
import Grades from "@/base/project/grades.js";

import { hasValue } from "@/base/lib/object";

import IconMinusInCircle from "@/base/icons/minus-in-circle/index.js";
import IconPlusInCircle from "@/base/icons/plus-in-circle/index.js";

import ButtonBig from "@/base/components/button-big/index.js";
import SelectCustom from "@/base/components/select-custom/index.js";
import WithScroll from "@/base/components/with-scroll/index.js";
import IconClickable from "@/base/components/icon-clickable/index.js";
import Error from "@/base/components/forms/error/index.js";
import Checkbox from "@/base/components/forms/checkbox/index.js";

import styles from "./styles.module.css";


const LMSClassesForm = (props) => {
    const [visibleSchools, setVisibleSchools] = useState({});

    const onToggleSchool = (schoolId) => {
        setVisibleSchools((prev) => ({
            ...prev,
            [schoolId]: !prev[schoolId],
        }));
    };

    const isDisabled = (formProps) => {
        const hasValues = Object.keys(formProps.values).length > 0;
        const hasErrors = Object.keys(formProps.errors).length > 0;

        return !hasValues
            || !hasValue(formProps.values)
            || hasErrors
            || formProps.isSubmitting;
    };

    const validateForm = (values) => {
        const errorsValue = !hasValue(values);

        const errors = {};

        if (errorsValue) {
            errors.values = "Please select at least one class";
        }

        return errors;
    };

    const onSubmit = (values, { setSubmitting }) => {
        const classes = [];

        Object.keys(values).forEach((key) => {
            if (values[key].isChecked) {
                const classId = key;
                const schoolClass = LMS.getProviderClassById(props.providers, classId);

                if (schoolClass) {
                    classes.push({
                        ...schoolClass,
                        grade: values[key].grade,
                    });
                }
            }
        });

        props.onSubmit({
            classes,
            setSubmitting,
        });
    };

    const renderErrors = (errors) => {
        if (!errors.values) {
            return null;
        }

        return (
            <Error>{errors.values}</Error>
        );
    };

    const renderSchool = (formProps, school) => {
        const {
            values,
            setFieldValue,
        } = formProps;

        const isVisible = visibleSchools[school.id];

        let classes = null;

        if (isVisible) {
            const schoolClasses = school.classes || [];

            const cs = schoolClasses.map((cl, index) => {
                const isCheckedByInit = props.initialValues?.[cl.id]?.isChecked;

                const isCheckedClass = values[cl.id]?.isChecked || false;
                const classGrade = values?.[cl.id]?.grade || Grades.getMaxGradeValue();

                let gradeSelect = null;

                if (isCheckedClass) {
                    gradeSelect = (
                        <SelectCustom
                            key={`${school.id}-${cl.id}`}
                            options={Grades.getGradesOptions()}
                            selected={classGrade}
                            disabled={isCheckedByInit}
                            onSelect={(val) => {
                                setFieldValue(cl.id, {
                                    isChecked: true,
                                    grade: val,
                                });
                            }}
                            isDropUp={index >= 5 && index > schoolClasses.length - 4}
                        />
                    );
                }

                return (
                    <div className={styles.classRow}>
                        <Checkbox
                            key={`${school.id}-${cl.id}`}
                            name={cl.id}
                            label={cl.name}
                            checked={isCheckedClass}
                            onChange={(evt) => {
                                setFieldValue(cl.id, {
                                    isChecked: evt.target.checked,
                                    grade: classGrade,
                                });
                            }}
                        />
                        {gradeSelect}
                    </div>
                );
            });

            classes = (
                <div className={styles.classes}>
                    {cs}
                </div>
            );
        }

        const icon = isVisible
            ? <IconMinusInCircle title="Collapse" />
            : <IconPlusInCircle title="Expand" />;

        return (
            <div className={styles.school}>
                <div className={styles.schoolName}>
                    <IconClickable
                        onClick={() => {
                            onToggleSchool(school.id);
                        }}
                    >
                        {icon}
                    </IconClickable>

                    <div
                        onClick={() => { onToggleSchool(school.id); }}
                        onKeyPress={() => { onToggleSchool(school.id); }}
                        role="button"
                        tabIndex="-1"
                    >
                        {school.name}
                    </div>
                </div>

                {classes}
            </div>
        );
    };

    const renderProvider = (formProps, provider) => {
        const schools = provider.schools || [];
        const ss = schools.map((s) => renderSchool(formProps, s));

        return (
            <div key={provider.providerName} className={styles.provider}>
                <div className={styles.providerName}>
                    {provider.providerName}
                </div>

                {ss}
            </div>
        );
    };

    const renderProviders = (formProps) => {
        if (props.providers.length === 0) {
            return (
                <div className={styles.message}>
                    No providers
                </div>
            );
        }

        const ps = props.providers.map((p) => renderProvider(formProps, p));

        return (
            <WithScroll className={styles.providers}>
                {ps}
            </WithScroll>
        );
    };

    const renderForm = (formProps) => {
        const {
            handleSubmit,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                {renderProviders(formProps)}

                {renderErrors(formProps.errors)}

                <div className={styles.buttons}>
                    <ButtonBig disabled={isDisabled(formProps)}>
                        Link Classes
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

LMSClassesForm.defaultProps = {
    initialValues: {},
    onSubmit: () => { },
};

export default LMSClassesForm;
