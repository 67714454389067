export const SET_COMPLETED_ASSIGNMENTS_BY_PAGE = "SET_COMPLETED_ASSIGNMENTS_BY_PAGE";
export const SET_COMPLETED_ASSIGNMENTS_BY_PAGE_LOADING = "SET_COMPLETED_ASSIGNMENTS_BY_PAGE_LOADING";
export const CLEAR_COMPLETED_ASSIGNMENTS_BY_PAGE = "CLEAR_COMPLETED_ASSIGNMENTS_BY_PAGE";

export const SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE_LOADING = "SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE_LOADING";
export const SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE = "SET_INCOMPLETE_ASSIGNMENTS_BY_PAGE";
export const CLEAR_INCOMPLETE_ASSIGNMENTS = "CLEAR_INCOMPLETE_ASSIGNMENTS";

export const SET_ASSIGNMENTS_BY_PAGE_LOADING = "SET_ASSIGNMENTS_BY_PAGE_LOADING";
export const SET_ASSIGNMENTS_BY_PAGE = "SET_ASSIGNMENTS_BY_PAGE";
export const CLEAR_ASSIGNMENTS_BY_PAGE = "CLEAR_ASSIGNMENTS_BY_PAGE";

export const SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID_LOADING = "SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID_LOADING";
export const SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID = "SET_STUDENT_ASSIGNMENTS_INCOMPLETE_ASSIGNMENTS_BY_CLASS_ID";

export const SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID_LOADING = "SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID_LOADING";
export const SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID = "SET_STUDENT_ASSIGNMENTS_COMPLETED_ASSIGNMENTS_BY_CLASS_ID";
