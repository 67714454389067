import text from "@/base/text/index.js";


const loadStudentAssignmentsByClassId = (services, params) => {
    const { actions, api } = services;
    const { classId, studentId } = params;

    return async (dispatch, getState) => {
        if (!studentId) {
            return;
        }

        const state = getState();

        dispatch(actions.teacherStudentsAssignments.setStudentAssignmentsByClassIdLoading({
            studentId,
            classId,
        }));

        const res = await api.students.getStudentAssignmentsByClassId({
            session: state.user.session,
            classId,
            studentId,
            page: state.teacherStudentsAssignments.assignmentsByStudentId?.[studentId]?.[classId]?.page || 0,
        });

        let data = [];
        let error = "";
        let hasMore = false;

        if (res.ok) {
            data = res.assignments;
            hasMore = res.hasMore;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.teacherStudentsAssignments.setStudentAssignmentsByClassId({
            classId,
            studentId,
            data,
            error,
            hasMore,
        }));
    };
};


export default {
    loadStudentAssignmentsByClassId,
};
